/**
 * Global texts which are used in shared components or are commons to all applications.
 * Examples: placeholders or texts in shared components
 */

/**
 * Fields placeholder
 */
const placeHolderTexts = {
    birthdatePlaceholder: "Date de naissance * (JJ/MM/AAAA)",
    cesuAccountPlaceholder: "Compte CESU",
    cesuCodePlaceholder: "Code CESU",
    commentPlaceholder: "Commentaire",
    confirmEmailPlaceholder: "Confirmer votre Email",
    confirmNewPasswordPlaceholder: "Confirmation nouveau mot de passe",
    confirmPasswordPlaceholder: "Confirmation du nouveau mot de passe",
    oldPasswordPlaceholder: "Ancien mot de passe",
    emailPlaceholder: "Email",
    facialValuePlaceholder: "Valeur faciale",
    firstnamePlaceholder: "Prénom",
    interlocutorPlaceholder: "Interlocuteur",
    lastnamePlaceholder: "Nom",
    loginPlaceholder: "Identifiant",
    mainPhoneNumberPlaceholder: "Tél. Principal",
    newPasswordPlaceHolder: "Nouveau mot de passe",
    passwordPlaceholder: "Mot de passe",
    phoneNumberPlaceholder: "N° de téléphone",
    recipientPlaceholder: "Destinataire",
    statusPlaceholder: "Statut",
    secondaryPhoneNumberPlaceholder: "Tél. Secondaire",
    selectPlaceholder: "Sélectionner",
    smsPlaceholder: "SMS",
    zoneAddress0Placeholder: "Destinataire",
    zoneAddress1Placeholder: "Complément d'adresse (ex : ZAC les Chemins)",
    zoneAddress2Placeholder: "Numéro, type et nom de voie (ex: 12 bis rue de lessard)",
    zoneAddress3Placeholder: "Lieu dit",
    postalCodeTownPlaceHolder: "Code postal, Ville *",
    hourPlaceholder: "Heure",

    simpleBirthdatePlaceholder: "Date de naissance",
    yesPlaceholder: "Oui",
    noPlaceholder: "Non",
}

export const sharedGlobalTexts = {
    ...placeHolderTexts,

    // COOKIES
    cookiesFooterLabel: "Gestion des cookies",
    cookiesStepOneTitle: "Gérer mes cookies",
    cookiesStepOneParagraph:
        "Nous utilisons des cookies, y compris des cookies tiers, à des fins de fonctionnement, d'analyses statistiques, de personnalisation de votre expérience, " +
        "afin de vous proposer des contenus ciblés adaptés à vos centres d'intérêts. Pour en savoir plus, consultez notre politique de données personnelles, disponible à tout moment en bas de page du site. " +
        "Vous pouvez paramétrer, accepter ou refuser ces cookies dès maintenant.",
    cookiesStepTwoTitle: "Gestion de vos préférences sur les cookies",
    cookiesStepTwoParagraph1:
        "Sur ce site nous utilisons des outils de mesure d’audience pour comprendre le parcours de navigation des utilisateurs afin d’optimiser l’ergonomie et la mise en avant des contenus" +
        " les plus pertinents. ",
    cookiesStepTwoParagraph2:
        "Ces cookies ne sont déposés que si vous donnez votre accord. Vous pouvez ci-dessous vous informer " +
        "sur la nature de chacun des cookies déposés, les accepter ou les refuser. Ce choix est possible soit globalement pour l’ensemble du site et l’ensemble des services, soit service par service.",
    cookiesStepTwoParagraph3: "En savoir plus sur les cookies",
    cookiesStepTwoParagraph3Link: "https://www.cnil.fr/fr/cookies-et-traceurs-que-dit-la-loi",
    cookiesStepTwoSubtitle: "Préférence pour tous les services",
    cookiesStepTwoTitleParameter1: "Fonctionnement du site (Obligatoire)",
    cookiesStepTwoTitleParameter2: "Mesure d’audience",
    cookiesStepTwoTitle1Paragraph:
        "Ces cookies sont nécessaires pour assurer le fonctionnement optimal du site et ne peuvent être paramétrés. Ils nous permettent de vous offrir les principales fonctionnalités du site " +
        "(langue utilisée, résolution d'affichage, accessibilité, etc.).",
    cookiesStepTwoTitle2Paragraph:
        "Ces cookies permettent d’avoir une finalité strictement limitée à la seule mesure de l’audience du site ou de l’application (mesure des performances, " +
        "détection de problèmes de navigation, optimisation des performances techniques ou de son ergonomie, estimation de la puissance des serveurs nécessaires, analyse des contenus consulté), pour le compte exclusif de Domiserve.",
    cookiesStepTwoTitle2AtInternet: "Matomo",
    cookiesStepTwoTitle2AtInternetLinkName: "> Lien vers la politique de confidentialité",
    cookiesStepTwoTitle2AtInternetLink: "https://fr.matomo.org/privacy/",

    cookiesRefuseButton: "Refuser",
    cookiesAcceptButton: "Accepter",
    cookiesWithoutAccept: "Continuer sans accepter",
    cookiesAcceptAllButton: "Tout accepter",
    cookiesCustomLink: "Personnaliser",

    // MENTIONS LEGALES

    mentionsLegalesTitrePage: "Mentions légales",
    mentionsLegalesTitre1: "Éditeur",
    mentionsLegalesTitre2: "Dénomination sociale",
    mentionsLegalesParagraph1:
        "Domiserve, Société par Actions Simplifiée au capital de 3 141 000 €, immatriculée au RCS de Nanterre, sous le numéro 484 170 832.",
    mentionsLegalesTitre3: "Siège social",
    mentionsLegalesParagraph2: "106 avenue Marx Dormoy - 92120 Montrouge",
    mentionsLegalesTitre4: "Numéro de téléphone",
    mentionsLegalesParagraph3: "01 78 16 14 59",
    mentionsLegalesParagraph4:
        "Dans le cadre de son activité d'émetteur de Chèques Emploi-Service Universels préfinancés, Domiserve bénéficie d'une habilitation de l'administration française, sous le n°2005003, et est soumise aux dispositions du Code du Travail (Articles D.1271-8 et suivants). Dans le cadre de son activité d'enseigne nationale de service à la personne, Domiserve +, SAS au capital de 303 750 euros immatriculée au RCS de Nanterre, sous le numéro 493 837 702, dispose d'une déclaration d'activités de services à la personne sous le numéro SAP 493837702.",
    mentionsLegalesTitre5: "Propriétaire de l'Entreprise",
    mentionsLegalesParagraph5:
        "Domiserve est détenue à 100% par la S.A.S. Domiserve Holding elle-même détenue à 100% par La Banque Postale.",
    mentionsLegalesTitre6: "Président et Directeur de la publication",
    mentionsLegalesParagraph6: "François BARBEY",
    mentionsLegalesTitre7: "Responsable de la rédaction",
    mentionsLegalesParagraph7: "Malika DUBOIS-MOUSSAOUI, Directrice Marketing et Communication",
    mentionsLegalesTitre8: "Contact",
    mentionsLegalesTitre9: "Hébergeur",
    mentionsLegalesParagraph8: "HARDIS GROUPE",
    mentionsLegalesParagraph9: "34 rue de la Tuilerie",
    mentionsLegalesParagraph10: "38170 Seyssinet-Pariset",
    mentionsLegalesTitre10: "Droit d’auteur",
    mentionsLegalesParagraph11: "Le site",
    mentionsLegalesParagraph12:
        "constitue une oeuvre dont la société Domiserve est l’auteur, protégée par la législation en vigueur (article L.122-4 du code de la propriété intellectuelle).",
    mentionsLegalesParagraph13:
        "Les textes, photographies, etc. insérés sur le site sont la propriété de Domiserve ou de tiers ayant autorisé Domiserve à les utiliser.",
    mentionsLegalesParagraph14:
        "Toute reproduction intégrale ou partielle du site est interdite sauf accord préalable express du Directeur de la publication.",
    mentionsLegalesParagraph15:
        "La société Domiserve se réserve le droit de refuser l’utilisation de son logo par un tiers, sans son accord express.",
    mentionsLegalesParagraph16:
        "Toute reproduction autorisée (dans un but personnel, associatif ou professionnel) implique l’indication de la source, site internet de la société Domiserve, accompagnée de l’adresse du site internet.",
    mentionsLegalesTitre11: "Informatique et libertés",
    mentionsLegalesParagraph17:
        "Depuis le 25 mai 2018, la réglementation sur les données personnelles a évolué avec l'entrée en vigueur du Règlement Général sur la Protection des Données.",
    mentionsLegalesParagraph18:
        "Domiserve, filiale à 100 % de La Banque Postale, s'engage à respecter l'ensemble des principes énoncés dans la Politique du Groupe La Banque Postale.",
    mentionsLegalesParagraph19: "Découvrez",
    mentionsLegalesParagraph20:
        "« La Politique de confidentialité et de protection des Données Personnelles du Groupe La Banque Postale »",
    mentionsLegalesParagraph21:
        "qui explique les principes et les valeurs du Groupe concernant vos données personnelles.",
    mentionsLegalesTitre12: "Droit d’accès",
    mentionsLegalesParagraph22:
        "Conformément à l’article 32 de la Loi Informatique et Libertés, vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données qui vous concernent.",
    mentionsLegalesParagraph23: "Pour exercer ce droit d’accès, adressez-vous à : ",
    mentionsLegalesParagraph24: "Domiserve",
    mentionsLegalesParagraph25: "Direction des Services Informatiques",
    mentionsLegalesParagraph26: "106 avenue Marx Dormoy 92120 Montrouge",
    mentionsLegalesTitre13: "Responsabilité",
    mentionsLegalesParagraph27:
        "Domiserve décline toute responsabilité quant au contenu du site et à l’utilisation qui pourrait en être faite par quiconque. Domiserve n’est responsable ni de l’exactitude, ni des erreurs, ni des omissions contenues sur ce site.",
    mentionsLegalesParagraph28: "L’utilisateur est seul responsable de l’utilisation de telles informations.",
    mentionsLegalesParagraph29:
        "Domiserve se réserve le droit de modifier à tout moment les présentes notamment en actualisant ce site.",
    mentionsLegalesParagraph30:
        "Domiserve ne pourra être responsable pour quel que dommage que ce soit tant direct qu’indirect, résultant d’une information contenue sur ce site. L’utilisateur s’engage à ne transmettre sur ce site aucune information pouvant entraîner une responsabilité civile ou pénale et s’engage à ce titre à ne pas divulguer via ce site des informations illégales, contraires à l’ordre public ou diffamatoires. Les sites extérieurs à la société Domiserve ayant un lien hypertexte avec le présent site ne sont pas sous le contrôle de Domiserve. Nous déclinons, par conséquent, toute responsabilité quant à leur contenu ou leurs conséquences. L’utilisateur est seul responsable de leur utilisation.",
    mentionsLegalesTitre14: "Politique de liens",
    mentionsLegalesParagraph31:
        "L’établissement d’un lien hypertexte vers le présent site nécessite une autorisation préalable de Domiserve. Dans ce cas il vous est possible de prendre contact avec le Directeur de la Publication. La mise en place d’un lien hypertexte, après autorisation, pourra être valable sur différents supports, à l’exception de ceux diffusant des informations à caractère politique, religieux, pornographique, xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre, ou encore contraires aux dispositions légales et réglementaires en vigueur. A cet égard, Domiserve se réserve un droit d’opposition sur tout lien mis en place sans son autorisation et pouvant porter atteinte à son image, ses activités, ses valeurs, sa réputation, ou sa notoriété, sans préjudice de poursuites éventuelles. Tout établissement de lien hypertexte accepté par Domiserve doit se faire dans une fenêtre entière avec l’URL :",
    mentionsLegalesParagraph32:
        "Les liens proposés vers d’autres sites sont communiqués à titre indicatif ; ils ne sauraient engager la responsabilité de Domiserve, tant en ce qui concerne les contenus que les conditions d’accès. Si les responsables d’un site « cible » souhaitent modifier leur mode de présence sur le présent site, ils peuvent en faire la demande en écrivant par email à",
    mentionsLegalesTitre15: "Contenu invisible, cookies",
    mentionsLegalesParagraph33:
        "L'internaute est informé que lors de ses visites sur le site, un cookie peut s'installer automatiquement sur son logiciel de navigation. Un cookie est un élément qui ne permet pas d'identifier l'utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le Site. L'internaute pourra désactiver ce cookie par l'intermédiaire des paramètres figurant au sein de son logiciel de navigation.",
    mentionsLegalesParagraph34: "Conditions Générales d'utilisation de nos services",
    mentionsLegalesEmailDomiserve: "contact@domiserve.com",

    // DOMISERVE POSTAL ADDRESS
    domiserveAddress1: "DOMISERVE - BO",
    domiserveAddress2: "106, avenue Marx Dormoy",
    domiserveAddress3: "92120 MONTROUGE",

    // COMMON TEXTS
    mandatoryField: "* Champs obligatoires",
    noLabel: "Non",
    yesLabel: "Oui",
    orLabel: "ou",
    actions: "Actions",

    // COMPONENTS
    // MissionInformations
    missionInformationsWorkedDaysTypePlaceHolder: "Jours travaillés",
    missionInformationsWorkedDaysTypeWeek: "Semaine",
    missionInformationsWorkedDaysTypeWeekEnd: "Week-end",
    missionInformationsWorkedDaysTypePublicHoliday: "Jour férié",
    missionInformationsMissionStartDatePlaceHolder: "Date de début de mission",
    missionInformationsMissionEndDatePlaceHolder: "Date de fin de mission",
    missionInformationsMissionHourStartPlaceHolder: "Heure de début",
    missionInformationsMissionHourEndPlaceHolder: "Heure de fin",
    missionInformationsVariableHoursRadio: "L’intérimaire a-t-il des horaires variables ?",
    missionInformationsVariableHoursDropdownPlaceholder: "Type de sélection",
    missionInformationsVariableHoursDropdownDate: "Date(s)",
    missionInformationsVariableHoursDropdownWeekdays: "Jours de la semaine",
    missionInformationsVariableHoursDropdownWeekdaysMonday: "Lundi",
    missionInformationsVariableHoursDropdownWeekdaysTuesday: "Mardi",
    missionInformationsVariableHoursDropdownWeekdaysWednesday: "Mercredi",
    missionInformationsVariableHoursDropdownWeekdaysThursday: "Jeudi",
    missionInformationsVariableHoursDropdownWeekdaysFriday: "Vendredi",
    missionInformationsVariableHoursDropdownWeekdaysSaturday: "Samedi",
    missionInformationsVariableHoursDropdownWeekdaysSunday: "Dimanche",

    missionInformationsVariableHoursAddLine: "Ajouter une ligne",

    // TermsOfService
    termsOfServiceAcceptance: "J'accepte les ",
    termsOfServiceName: "Conditions Générales de Domiserve",
    termsOfServiceText: "Registration Overview Terms Of Service",
    termsOfServiceCGV: "Conditions Générales de Ventes",
    termsOfServiceCUS: "Conditions d'utilisation du service",
    termsOfServiceCGUS: "Conditions Générales d'Utilisation du Service",

    // orderCard
    orderCardcompteECesu: "Compte e-CESU",
    orderCardcompteECesuLi1: "Sécurisé",
    orderCardcompteECesuLi2: "Rapide",
    orderCardcompteECesuLi3: "Écologique",
    orderCardcompteECesuLi4:
        "Pratique : règlement au centime près et complément CB possible, déclaration URSSAF pour l’emploi direct.",
    orderCardTitrePapierCesu: "Titres CESU papier",
    orderCardTitrePapierCesuLi1: "Adaptés au paiement de certaines structures collectives de garde d’enfant.",
    orderCardRecommande: "recommandé",
    orderCardPlafondInfo: "Le plafond d'exonération est fixé à ",
    orderCardPlafondInfo2: " par bénéficiaire et par année fiscale.",
    orderCardMontant: "Montant (€)*",
    orderCardPartFinancee: "Part financée (€)",
    orderCardChequier: "Chéquier (€)*",
    orderCardNombreTitres: "Nombre de titres *",
    orderCardValeurTitre: "Valeur d'un titre (€)*",
    orderCardTotalCesu: "Total CESU",
    orderCartMinCheckValue: "La valeur minimale est de 15€",
    orderCartValidateCheckTitleFunded: "La part financée ne peut être supérieure au montant commandé.",

    // PAGES
    // NOT FOUND PAGE
    notFoundText: "Page introuvable.",

    // ERROR PAGE
    technicalErrorText: "Il semblerait qu'une erreur technique se soit produite.",

    // PASSWORD POPUP
    passwordPopup: "Votre mot de passe doit valider les règles de sécurité listées ci-dessous.",

    // PASSWORD FIELD
    passwordFieldPasswordTypePopup: "Afficher le mot de passe",
    passwordFieldTextTypePopup: "Masquer le mot de passe",

    // PASSWORD RULES
    passwordRulesParagraphInfos:
        "Votre mot de passe doit respecter toutes les règles, une fois validées elles passeront en vert.",
    passwordRulesExpectedLength: "Le mot de passe doit contenir au moins 8 caractères",
    passwordRulesMatchingZxcvbnScore: "Le mot de passe ne doit pas être trop simple à deviner",
    passwordRulesMatchingExpectedAmountOfRules: "Le mot de passe remplit les critères de sécurité recommandés",
    passwordRulesUpperCase: "Le mot de passe doit contenir au moins 1 lettre majuscule",
    passwordRulesLowerCase: "Le mot de passe doit contenir au moins 1 lettre minuscule",
    passwordRulesDigit: "Le mot de passe doit contenir au moins 1 chiffre",
    passwordRulesSpecialCharacter: "Le mot de passe doit contenir au moins 1 caractère spécial",
    passwordRulesSamePassword: "Le mot de passe et le mot de passe de confirmation doivent être identiques",

    // UNAUTHORIZED PAGE
    unauthorizedText: "Oups, vous vous êtes perdu ?",

    // BUTTONS
    activateButton: "Activer",
    continueButton: "Continuer",
    browseButton: "Parcourir",
    cancelButton: "Annuler",
    closeButton: "Fermer",
    copyButton: "Copier",
    createButton: "Créer",
    deactivateButton: "Désactiver",
    editButton: "Modifier",
    returnButton: "Retour",
    saveButton: "Enregistrer",
    searchButton: "Rechercher",
    selectButton: "Sélectionner",

    // CARE LINES
    careLinesPrestationDatesLabel: "Date(s) de prestation",
    careLinePrestationStartDateLabel: "Heure de début",
    careLinePrestationEndDateLabel: "Heure de fin",
    careLineAddLineLabel: "Ajouter une ligne",

    // SELECT OPTIONS
    notSpecifiedOption: "Non renseigné",

    // RECOVER LOGIN PASSWORD PAGE
    recoverLoginPasswordHeader: "Email envoyé",
    recoverLoginPasswordSuccess: "Un email a été envoyé à l'adresse renseignée sur votre profil.",

    tableTotalResultText: totalResults =>
        !!totalResults && totalResults !== 0 ? `${totalResults} résultat(s).` : "Pas de résultat.",

    // RESET PASSWORD / LOGIN
    loginLabel: "Identifiant",
    oldPasswordLabel: "Ancien mot de passe *",
    validateButtonLabel: "Valider",
    resetPasswordValidateUpdate: "Valider le changement",
    resetPasswordInformationMessage: "Pour des raisons de sécurité, veuillez changer votre mot de passe.",

    // HEADER
    headerLogin: "Se connecter",
    headerLogout: "Se déconnecter",

    //DEMANDE SERVICE ZONE INTERVENTION
    demandeServicePrestataireTitle: "Choix prestataire",
    demandeServicePrestataireParagraph:
        "Nous ne travaillons qu'avec des prestataires rigoureusement référencés. Si vous souhaitez un prestataire en particulier, nous nous efforcerons de répondre à votre demande parmi notre réseau.",
    demandeServicePrestataireLastPrestataire: "Dernier prestataire intervenu disponible :   ",
    demandeServicePrestataireEnParticulier: "Souhaitez-vous faire appel au dernier prestataire intervenu ?",
    demandeServicePrestataireHabituel: "Avez-vous un prestataire habituel ?",
    demandeServiceOtherPrestataireHabituel: "Avez-vous un autre prestataire habituel ?",
    demandeServiceSearchEmpty: "Pas de prestataire(s) répondant aux critères",
    demandeServiceSearchNotEmpty: "résultat(s)",
    demandeServicePrestataireDenominationDomiserve: "Dénomination Domiserve",
    demandeServicePrestatairePostalCodeTown: "CP, Ville",
    demandeServicePrestataireNetwork: "Réseau",
    demandeServicePrestatairePhone: "Téléphone(s)",
    demandeServicePrestataireComment: "Commentaires",
    demandeServicePrestataireAccordMission: "Avez-vous l'accord de ce prestataire pour cette mission ?",
    demandeServicePrestataireAutrePrestaSiNonDispo:
        "Si votre prestataire habituel ne peut pas assurer la mission, nous vous mettrons en relation avec un autre prestataire de confiance.",

    // SEARCH PRESTATAIRE
    searchPrestataireLabel: "Recherche de prestataire",
    searchPrestatairePlaceholder: "Saisir",
    searchPrestataireButton: "Rechercher",
}
