/**
 * The version key.
 */
export const VersionKeys = {
    V0_0_0: "V0.0.0",
    V1_0_0: "V1.0.0",
    V1_0_1: "V1.0.1",
    V1_0_2: "V1.0.2",
    V1_1_0: "V1.1.0",
    V1_2_0: "V1.2.0",
    V1_3_0: "V1.3.0",
    V1_3_1: "V1.3.1",
    V1_4_0: "V1.4.0",
    V1_5_0: "V1.5.0",
    V1_6_0: "V1.6.0",
    V1_7_0: "V1.7.0",
    V1_8_0: "V1.8.0",
    V1_8_1: "V1.8.1",
    V1_8_2: "V1.8.2",
    V1_9_0: "V1.9.0",
    V1_10_0: "V1.10.0",
    V1_11_0: "V1.11.0",
    V1_12_0: "V1.12.0",
    V1_13_0: "V1.13.0",
    V2_0_0: "V2.0.0",
    V2_1_0: "V2.1.0",
    V2_2_0: "V2.2.0",
    V2_3_0: "V2.3.0",
    V2_4_0: "V2.4.0",
    V2_5_0: "V2.5.0",
    V2_5_1: "V2.5.1",
}

/**
 * The version structure.
 */
export const Versions = {
    [VersionKeys.V0_0_0]: {
        emptyState: false,
        next: VersionKeys.V1_0_0,
    },
    [VersionKeys.V1_0_0]: {
        emptyState: false,
        next: VersionKeys.V1_0_1,
    },
    [VersionKeys.V1_0_1]: {
        emptyState: false,
        next: VersionKeys.V1_0_2,
    },
    [VersionKeys.V1_0_2]: {
        emptyState: false,
        next: VersionKeys.V1_1_0,
    },
    [VersionKeys.V1_1_0]: {
        emptyState: false,
        next: VersionKeys.V1_2_0,
    },
    [VersionKeys.V1_2_0]: {
        emptyState: false,
        next: VersionKeys.V1_3_0,
    },
    [VersionKeys.V1_3_0]: {
        emptyState: false,
        next: VersionKeys.V1_3_1,
    },
    [VersionKeys.V1_3_1]: {
        emptyState: false,
        next: VersionKeys.V1_4_0,
    },
    [VersionKeys.V1_4_0]: {
        emptyState: false,
        next: VersionKeys.V1_5_0,
    },
    [VersionKeys.V1_5_0]: {
        emptyState: false,
        next: VersionKeys.V1_6_0,
    },
    [VersionKeys.V1_6_0]: {
        emptyState: false,
        next: VersionKeys.V1_7_0,
    },
    [VersionKeys.V1_7_0]: {
        emptyState: false,
        next: VersionKeys.V1_8_0,
    },
    [VersionKeys.V1_8_0]: {
        emptyState: false,
        next: VersionKeys.V1_8_1,
    },
    [VersionKeys.V1_8_1]: {
        emptyState: false,
        next: VersionKeys.V1_8_2,
    },
    [VersionKeys.V1_8_2]: {
        emptyState: false,
        next: VersionKeys.V1_9_0,
    },
    [VersionKeys.V1_9_0]: {
        emptyState: false,
        next: VersionKeys.V1_10_0,
    },
    [VersionKeys.V1_10_0]: {
        emptyState: false,
        next: VersionKeys.V1_11_0,
    },
    [VersionKeys.V1_11_0]: {
        emptyState: false,
        next: VersionKeys.V1_12_0,
    },
    [VersionKeys.V1_12_0]: {
        emptyState: false,
        next: VersionKeys.V1_13_0,
    },
    [VersionKeys.V1_13_0]: {
        emptyState: false,
        next: VersionKeys.V2_0_0,
    },
    [VersionKeys.V2_0_0]: {
        emptyState: false,
        next: VersionKeys.V2_1_0,
    },
    [VersionKeys.V2_1_0]: {
        emptyState: true,
        next: VersionKeys.V2_2_0,
    },
    [VersionKeys.V2_2_0]: {
        emptyState: true,
        next: VersionKeys.V2_3_0,
    },
    [VersionKeys.V2_3_0]: {
        emptyState: false,
        next: VersionKeys.V2_4_0,
    },
    [VersionKeys.V2_4_0]: {
        emptyState: false,
        next: VersionKeys.V2_5_0,
    },
    [VersionKeys.V2_5_0]: {
        emptyState: false,
        next: VersionKeys.V2_5_1,
    },
    [VersionKeys.V2_5_1]: {
        emptyState: false,
    },
}
